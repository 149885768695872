import React from 'react';
import ReactDOM from 'react-dom';
import { observerBatching } from 'mobx-react-lite';
observerBatching(ReactDOM.unstable_batchedUpdates);

import reactUjs from './reactUjs';

const CLASS_ATTRIBUTE_NAME = 'data-react-class';
const PROPS_ATTRIBUTE_NAME = 'data-react-props';

const ComponentRegister = {
  registeredComponents: {},

  render(node, component) {
    const propsJson = node.getAttribute(PROPS_ATTRIBUTE_NAME);
    const props = propsJson && JSON.parse(propsJson);

    const reactElement = React.createElement(component, props);

    ReactDOM.render(reactElement, node);
  },

  registerComponents(components) {
    Object.assign(this.registeredComponents, components);
    return true;
  },

  mountComponents() {
    const { registeredComponents } = this;
    const toMount = document.querySelectorAll(`[${CLASS_ATTRIBUTE_NAME}]`);

    for (let i = 0; i < toMount.length; i += 1) {
      const node = toMount[i];
      const className = node.getAttribute(CLASS_ATTRIBUTE_NAME);
      const component = registeredComponents[className];

      if (component) {
        if (node.innerHTML.length === 0) this.render(node, component);
      } else {
        if (process.env.NODE_ENV != 'production') {
          console.error(
            `ComponentRegister: can not render a component that has not been registered: ${className}`
          );
        }
      }
    }
  },

  setup(components = {}) {
    if (typeof window.ComponentRegister === 'undefined') {
      window.ComponentRegister = this;
      reactUjs.setup(this.mountComponents.bind(this));
    }

    window.ComponentRegister.registerComponents(components);
    window.ComponentRegister.mountComponents();
  },
};

export default ComponentRegister;
