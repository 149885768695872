import aGrade from 'grades/a.svg';
import aPlusGrade from 'grades/a-plus.svg';
import bGrade from 'grades/b.svg';
import bPlusGrade from 'grades/b-plus.svg';
import cGrade from 'grades/c.svg';
import cPlusGrade from 'grades/c-plus.svg';
import dGrade from 'grades/d.svg';
import dPlusGrade from 'grades/d-plus.svg';
import fGrade from 'grades/f.svg';
import fPlusGrade from 'grades/f-plus.svg';
import notAvailable from 'grades/n-a.svg';

const gradeIcon = {
  A: aGrade,
  'A+': aPlusGrade,
  B: bGrade,
  'B+': bPlusGrade,
  C: cGrade,
  'C+': cPlusGrade,
  D: dGrade,
  'D+': dPlusGrade,
  F: fGrade,
  'F+': fPlusGrade,
  'N/A': notAvailable,
};

export default gradeIcon;
