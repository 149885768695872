import ComponentRegister from '../utils/ComponentRegister';

import RebateCalculator from '../bundles/RebateCalculator';
import ReviewCategories from '../bundles/ReviewCategories';
import RecentReview from '../bundles/RecentReview';
import MuiTextInput from '../bundles/MuiTextInput';
import StreamVideo from '../bundles/StreamVideo';
import PhotoViewer from '../bundles/PhotoViewer';

ComponentRegister.setup({
  RebateCalculator,
  ReviewCategories,
  RecentReview,
  MuiTextInput,
  StreamVideo,
  PhotoViewer,
});
