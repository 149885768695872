import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PropTypes from 'prop-types';

const MuiTextInput = observer(function MuiTextInput(props) {
  const {
    label,
    icon,
    name,
    errors,
    initialValue,
    inputLabelProps,
    ...inputProps
  } = props;

  const [value, setValue] = useState(initialValue);

  let startAdornment = null;
  if (icon) {
    startAdornment = {
      startAdornment: (
        <InputAdornment position="start">
          <FontAwesomeIcon icon={['fa', icon]} />
        </InputAdornment>
      ),
    };
  }

  return (
    <TextField
      name={name}
      onChange={(event) => setValue(event.target.value)}
      value={value}
      label={label}
      className="mui-text-field"
      variant="outlined"
      fullWidth
      InputProps={startAdornment}
      {...inputProps}
      error={errors && Boolean(errors[0])}
      helperText={errors && errors[0]}
      InputLabelProps={inputLabelProps}
    />
  );
});

MuiTextInput.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  initialValue: PropTypes.string.isRequired,
};

export default MuiTextInput;
