const reactUjs = {
  setup(onMount) {
    this.native(onMount);
  },

  native(onMount) {
    if ('addEventListener' in window) {
      document.addEventListener('DOMContentLoaded', onMount);
    } else {
      // add support to IE8 without jQuery
      window.attachEvent('onload', onMount);
    }
  },
};

export default reactUjs;
