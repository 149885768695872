import React from 'react';
import ImageGallery from 'react-image-gallery';
import { photoSets } from './utils/photoSets';
import StreamVideo from './StreamVideo';

const PhotoViewer = (props) => {
  const images = photoSets[props.photo_set];

  const itemRenderer = (item) => {
    if (item.type == 'video') {
      return (
        <div className="photo-viewer-video">
          <StreamVideo
            video_id={item.original}
            preload="metadata"
            allowFullScreen={false}
            poster={`${process.env.ROOT_URL}/${item.poster}`}
          />
        </div>
      );
    } else {
      return <img className="image-gallery-image" src={item.original} />;
    }
  };
  return (
    <ImageGallery
      items={images}
      showIndex={true}
      showThumbnails={true}
      lazyLoad={true}
      showPlayButton={false}
      renderItem={itemRenderer}
    />
  );
};

export default PhotoViewer;
