import onBasePhoto1 from 'home/on_base_houses/base-house-1.jpg';
import onBasePhoto2 from 'home/on_base_houses/base-house-2.jpg';
import onBasePhoto3 from 'home/on_base_houses/base-house-3.jpg';
import onBasePhoto4 from 'home/on_base_houses/base-house-4.jpg';
import onBasePhoto5 from 'home/on_base_houses/base-house-5.jpg';
import onBasePhoto6 from 'home/on_base_houses/base-house-6.jpg';
import onBasePhoto7 from 'home/on_base_houses/base-house-7.jpg';
import onBasePhoto8 from 'home/on_base_houses/base-house-8.jpg';
import onBasePhoto9 from 'home/on_base_houses/base-house-9.jpg';
import onBasePhoto10 from 'home/on_base_houses/base-house-10.jpg';
import destressThumbnail from 'home/destress-your-pcs-thumbnail-600x400.jpg';
import destressPoster from 'home/destress-your-pcs-poster-1144x644.jpg';

const onBase = [
  {
    original: '85f6fed1594e4e2b23cdcabbe1e4f4a4',
    thumbnail: destressThumbnail,
    poster: destressPoster,
    type: 'video',
  },
  { original: onBasePhoto1, thumbnail: onBasePhoto1, type: 'image' },
  { original: onBasePhoto2, thumbnail: onBasePhoto2, type: 'image' },
  { original: onBasePhoto3, thumbnail: onBasePhoto3, type: 'image' },
  { original: onBasePhoto4, thumbnail: onBasePhoto4, type: 'image' },
  { original: onBasePhoto5, thumbnail: onBasePhoto5, type: 'image' },
  { original: onBasePhoto6, thumbnail: onBasePhoto6, type: 'image' },
  { original: onBasePhoto7, thumbnail: onBasePhoto7, type: 'image' },
  { original: onBasePhoto8, thumbnail: onBasePhoto8, type: 'image' },
  { original: onBasePhoto9, thumbnail: onBasePhoto9, type: 'image' },
  { original: onBasePhoto10, thumbnail: onBasePhoto10, type: 'image' },
];

export const photoSets = {
  onBase: onBase,
};
