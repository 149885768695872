import React from 'react';
import gradeIcon from './VendorProfile/utils/gradeIcon';

const RecentReview = (props) => {
  const { title, grade, body, militaryAffilation } = props;

  return (
    <div className="recent-review flex py-4">
      <div className="mr-6 pt-2">
        <img
          className="profile-overall-grade"
          src={gradeIcon[grade]}
          alt={grade}
        />
      </div>
      <div>
        <h3 className="brand-blue-text mb-2 fs-base">{title}</h3>
        <p className="mb-2 fs-sm">{body}</p>
        <p className="font-semibold fs-sm">- {militaryAffilation}</p>
      </div>
    </div>
  );
};

export default RecentReview;
